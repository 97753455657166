import { Link } from 'react-router-dom';
import styles from './Button.module.css';

type ButtonProps = {
  text: string;
  backgroundColor: 'white' | 'black';
  href?: string;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
  className?: any;
  disabled?: boolean;
  icon?: string;
};

function Button({
  text,
  icon,
  backgroundColor,
  href,
  onClick,
  type = 'button',
  className,
  disabled = false
}: ButtonProps) {
  return href ? (
    <Link to={href}>
      <button
        className={`${styles.button} ${handleButtonColorClass(backgroundColor)} ${className && className}`}>
        {text}
        {icon && (
          <img
            src={icon}
            style={{
              width: 24,
              height: 24,
              marginLeft: 8
            }}
            alt="ícone do botão"
          />
        )}
      </button>
    </Link>
  ) : (
    <button
      type={type}
      className={`${styles.button} ${handleButtonColorClass(backgroundColor)} ${className && className}`}
      onClick={onClick}
      disabled={disabled}>
      {text}
      {icon && (
        <img
          src={icon}
          style={{
            width: 24,
            height: 24,
            marginLeft: 8
          }}
          alt="ícone do botão"
        />
      )}
    </button>
  );
}

function handleButtonColorClass(backgroundColor: string) {
  return backgroundColor === 'black' ? styles.button_black : styles.button_white;
}

export default Button;
