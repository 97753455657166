import InputMask from 'react-input-mask';
import styles from './InputField.module.css';

interface InputFieldProps {
  label?: string;
  placeholder: string;
  type?: string;
  mask?: string;
  register: any;
  error?: any;
  onClick?: any;
  imgButton?: any;
  customInputCSS?: any;
}

function InputField({
  label,
  placeholder,
  type = 'text',
  mask = '',
  register,
  error,
  onClick,
  imgButton,
  customInputCSS
}: InputFieldProps) {
  return (
    <div className={styles.inputFieldContainer}>
      {label && <label className={styles.inputLabel}>{label}</label>}
      {mask ? (
        <InputMask
          mask={mask}
          className={`${styles.inputField} ${error && styles.inputError}`}
          placeholder={placeholder}
          type={type}
          {...register}
        />
      ) : (
        <input
          className={`${styles.inputField} ${error && styles.inputError}`}
          style={customInputCSS}
          type={type}
          placeholder={placeholder}
          {...register}
        />
      )}
      {onClick && imgButton && (
        <button type="button" className={styles.inputButton} onClick={onClick}>
          <img src={imgButton} alt="Button Icon" className={styles.buttonIcon} />
        </button>
      )}
      {error && <p className={styles.errorText}>{error}</p>}
    </div>
  );
}

export default InputField;
