import { ReactComponent as LogoMinimalIcon } from '../../assets/images/logo-minimal.svg';
import styles from './Footer.module.css';
import { useWindowWidth } from '../../hooks/WindowSizeContext';
import { Link, useLocation } from 'react-router-dom';
import gradientImg from '../../assets/images/gradient.png';
import arrowRight from '../../assets/images/arrow-right.svg';
import InputField from '../InputField/InputField';
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';

function Footer() {
  const { windowWidth } = useWindowWidth();
  const location = useLocation();

  const isHome = location.pathname === '/';

  const DesktopFooter = () => (
    <footer className={styles.footer}>
      <div className={styles.footer_wrapper}>
        <div className={styles.contacts_wrapper}>
          <LogoMinimalIcon />
          <div className={styles.contacts_row}>
            <MailIcon style={{ height: 16, width: 16 }} />
            <p>apertai.adm@gmail.com</p>
          </div>
          <div className={styles.contacts_row}>
            <CallIcon style={{ height: 16, width: 16 }} />
            <p>{`(31) 99204-8792`}</p>
          </div>
        </div>
        <div className={styles.links_wrapper}>
          <h2>Links</h2>
          <Link to="/">Home</Link>
          <Link to="/videos">Vídeos</Link>
        </div>
        <div className={styles.links_wrapper}>
          <h2>Legal</h2>
          <a href="/termos-de-uso.pdf" target="_blank">
            Termos de uso
          </a>
          <Link to="/">Termos de privacidade</Link>
          <Link to="/">Cookies</Link>
        </div>
      </div>
      <div className={styles.copyright}>Copyright 2024 ApertAI todos os direitos reservados.</div>
    </footer>
  );

  const MobileFooter = () => (
    <footer className={`${styles.footer} ${isHome ? styles.footer_home : ''}`}>
      <div className={styles.links}>
        <div className={styles.newsletter_wrapper}>
          <h2>Newsletter</h2>
          <p>Receba atualizações sobre novas quadras e promoções.</p>
          <InputField
            placeholder="Seu email"
            register="email_newsletter"
            onClick={() => {}}
            imgButton={arrowRight}
            customInputCSS={{ padding: '16px 12px', fontSize: 16 }}
          />
        </div>
      </div>
      <div className={styles.links}>
        <div className={styles.links_wrapper}>
          <h2>Links</h2>
          <Link to="/">Home</Link>
          <Link to="/videos">Vídeos</Link>
        </div>
        <div className={styles.links_wrapper}>
          <a href="/termos-de-uso.pdf" target="_blank">
            Termos de uso
          </a>
          <Link to="/">Termos de privacidade</Link>
          <Link to="/">Cookies</Link>
        </div>
      </div>
      <div className={styles.contacts}>
        <div className={styles.contacts_wrapper}>
          <div className={styles.contacts_row}>
            <MailIcon style={{ height: 32, width: 32 }} />
            <p>apertai.adm@gmail.com</p>
          </div>
          <div className={styles.contacts_row}>
            <CallIcon style={{ height: 32, width: 32 }} />
            <p>{`(31) 99204-8792`}</p>
          </div>
        </div>
        <LogoMinimalIcon style={{ height: 40, width: 40 }} />
      </div>
      <div className={styles.copyright}>Copyright 2024 ApertAI todos os direitos reservados.</div>
      {location.pathname === '/' && (
        <img
          src={gradientImg}
          className={styles.gradient_footer}
          alt="Mancha para design com cor laranja"
        />
      )}
    </footer>
  );

  return windowWidth >= 768 ? <DesktopFooter /> : <MobileFooter />;
}

export default Footer;
