import styles from './index.module.css';
import cameraImg from '../../../../assets/images/camera.png';
import videoIcon from '../../../../assets/images/full-hd.png';
import toolsIcon from '../../../../assets/images/tools.png';
import securityIcon from '../../../../assets/images/security.png';
import { useWindowWidth } from '../../../../hooks/WindowSizeContext';

const DifferencialCard = () => {
  const { windowWidth } = useWindowWidth();

  const MobileDifferencialCard = () => (
    <div className={styles.differencial_card_container}>
      <h2 className={styles.differencial_card_title}>Nosso diferencial</h2>
      <img src={cameraImg} className={styles.differencial_card_image} alt="Imagem da Câmera" />
      <div className={styles.differencial_card_wrapper}>
        <img src={videoIcon} className={styles.differencial_card_icon} alt="Ícone de Vídeo" />
        <div className={styles.differencial_card_text}>
          <h3>Filmagem Full HD</h3>
          <p>Capture cada detalhe com qualidade Full HD em alta definição.</p>
        </div>
      </div>
      <div className={styles.differencial_card_wrapper}>
        <img src={toolsIcon} className={styles.differencial_card_icon} alt="Ícone de Ferramentas" />
        <div className={styles.differencial_card_text}>
          <h3>Facilidade de Instalação</h3>
          <p>Instalação rápida e simples, sem complicações.</p>
        </div>
      </div>
      <div className={styles.differencial_card_wrapper}>
        <img
          src={securityIcon}
          className={styles.differencial_card_icon}
          alt="Ícone de Segurança"
        />
        <div className={styles.differencial_card_text}>
          <h3>Resistência e Durabilidade</h3>
          <p>Câmera à prova d’água e sol, pronta para qualquer desafio.</p>
        </div>
      </div>
    </div>
  );

  const DesktopDifferencialCard = () => (
    <div className={styles.differencial_card_container}>
      <div className={styles.differencial_card_wrapper}>
        <img src={videoIcon} className={styles.differencial_card_icon} alt="Ícone de Vídeo" />
        <div className={styles.differencial_card_text}>
          <h3>Filmagem Full HD</h3>
          <p>Capture cada detalhe com qualidade Full HD em alta definição.</p>
        </div>
      </div>
      <div className={styles.differencial_card_wrapper}>
        <img
          src={securityIcon}
          className={styles.differencial_card_icon}
          alt="Ícone de Segurança"
        />
        <div className={styles.differencial_card_text}>
          <h3>Resistência e Durabilidade</h3>
          <p>Câmera à prova d’água e sol, pronta para qualquer desafio.</p>
        </div>
      </div>
      <div className={styles.differencial_card_wrapper}>
        <img src={toolsIcon} className={styles.differencial_card_icon} alt="Ícone de Ferramentas" />
        <div className={styles.differencial_card_text}>
          <h3>Facilidade de Instalação</h3>
          <p>Instalação rápida e simples, sem complicações.</p>
        </div>
      </div>
    </div>
  );

  return windowWidth >= 768 ? <DesktopDifferencialCard /> : <MobileDifferencialCard />;
};

export default DifferencialCard;
