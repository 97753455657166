import { ReactComponent as GoogleIcon } from '../../../../assets/images/google.svg';
import styles from './index.module.css';

interface GoogleButtonProps {
  onClick: () => void;
}

const GoogleButton = ({ onClick }: GoogleButtonProps) => {
  return (
    <button className={styles.gsi_material_button} onClick={onClick}>
      <div className={styles.gsi_material_button_state}></div>
      <div className={styles.gsi_material_button_content_wrapper}>
        <GoogleIcon style={{ height: 20, width: 20 }} />
        <span className={styles.gsi_material_button_contents}>Continue com Google</span>
      </div>
    </button>
  );
};

export default GoogleButton;
