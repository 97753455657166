import { Button, InputField, Navbar } from '../../components';
import { GoogleButton } from './components';
import styles from './index.module.css';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import {
  getRedirectResult,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup
} from 'firebase/auth';
import { auth } from '../../api/firebase';
import { Link, useNavigate } from 'react-router-dom';
import { useWindowWidth } from '../../hooks/WindowSizeContext';
import racketImg from '../../assets/images/racket.webp';
import { useEffect, useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';

const schema = z.object({
  email: z.string().email({ message: 'Email inválido, por favor conferir!' }),
  password: z.string().min(6, { message: 'A senha precisa ter pelo menos 6 caracteres.' }) // Minimum length
});

function Login() {
  const [loginError, setLoginError] = useState(false);
  const { windowWidth } = useWindowWidth();
  const googleProvider = new GoogleAuthProvider();
  const navigate = useNavigate();

  const loginWithGoogle = async () => {
    try {
      signInWithPopup(auth, googleProvider)
        .then((result: any) => {
          localStorage.setItem('accessToken', result.user.accessToken);
          return navigate('/videos');
        })
        .catch((e) => console.error(e));
    } catch (e) {
      console.error('Error during sign-in:', e);
    }
  };

  const loginWithEmail = async (data: any) => {
    const { email, password } = data;

    try {
      signInWithEmailAndPassword(auth, email, password)
        .then((result: any) => {
          localStorage.setItem('accessToken', result.user.accessToken);
          return navigate('/videos');
        })
        .catch(() => setLoginError(true));
    } catch (e) {
      setLoginError(true);
      console.error(e);
    }
  };

  setTimeout(() => {
    getRedirectResult(auth)
      .then((result: any) => {
        console.log('RESULT', result);
        if (result) {
          localStorage.setItem('accessToken', result.user.accessToken);
        }
      })
      .catch((error) => {
        console.error('Error during sign-in:', error);
      });
  }, 30000);

  useEffect(() => {
    return () => {
      setLoginError(false);
    };
  }, []);

  const handleForgotPassword = (email: string) => {
    if (!email) {
      alert('Por favor, insira seu email antes de solicitar a redefinição de senha.');
      return;
    }
    sendPasswordResetEmail(auth, email)
      .then(() => {
        alert('Verifique seu email para redefinir a senha.');
      })
      .catch((error) => {
        alert('Erro ao enviar o email de redefinição: ' + error.message);
      });
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues
  } = useForm({ resolver: zodResolver(schema) });

  return (
    <>
      <Navbar menuTextColor="white" />
      <div className={styles.login_container}>
        {windowWidth > 768 && (
          <img
            src={racketImg}
            className={styles.login_image}
            alt="Raquetes de beach tennis coloridas, com bolas de tênis espalhadas pela areia."
          />
        )}
        <div className={styles.login_form}>
          <h2>Login</h2>
          <GoogleButton onClick={loginWithGoogle} />
          <div className={styles.divider_group}>
            <div className={styles.divider}></div>
            <span className={styles.divider_text}>Ou</span>
            <div className={styles.divider}></div>
          </div>
          <form onSubmit={handleSubmit(loginWithEmail)}>
            <InputField
              label="Email"
              placeholder="fulano@gmail.com"
              register={register('email')}
              error={errors.email?.message}
            />
            <InputField
              label="Senha"
              placeholder="*******"
              type="password"
              register={register('password')}
              error={errors.password?.message}
            />
            {loginError && <div className={styles.error_message}>Usuário ou Senha incorretos!</div>}
            <div
              className={styles.forgot_password}
              onClick={() => handleForgotPassword(getValues('email'))}>
              Esqueci a senha
            </div>
            <Button
              backgroundColor="white"
              text="Entrar"
              type="submit"
              className={styles.button_login}
            />
            <div className={styles.signup}>
              Não tem uma conta? <Link to="/signup">Cadastre-se</Link>
            </div>
            <p className={styles.warning_login}>
              Ao continuar com o serviço Google você concorda com nossos termos de serviço e
              políticas de uso.
            </p>
          </form>
        </div>
      </div>
    </>
  );
}

export default Login;
