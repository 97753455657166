import styles from './index.module.css';

interface GoogleButtonProps {
  checked: boolean;
  onCheck: (checked: boolean) => void;
}

const AcceptTerms = ({ checked, onCheck }: GoogleButtonProps) => {
  function openPDF() {
    const pdfUrl = '/termos-de-uso.pdf';
    window.open(pdfUrl, '_blank', 'noopener,noreferrer');
  }

  return (
    <div className={styles.accept_terms}>
      <input type="checkbox" checked={checked} onChange={(e) => onCheck(e.target.checked)} />
      <span className={styles.checkbox}></span>
      <label>
        Eu concordo com os <span onClick={openPDF}>Termos de Serviço</span>.
      </label>
    </div>
  );
};

export default AcceptTerms;
