import styles from './index.module.css';
import smartphonesImg from '../../../../assets/images/smartphones.png';
import cameraIcon from '../../../../assets/images/camera-icon.svg';
import { Button } from '../../../../components';
import { useWindowWidth } from '../../../../hooks/WindowSizeContext';

const RecordCard = () => {
  const { windowWidth } = useWindowWidth();

  const handleContractCameraWhatsapp = () => {
    const confirmAction = window.confirm(
      'Você será redirecionado para uma conversa de WhatsApp. Deseja continuar?'
    );
    if (confirmAction) {
      window.open(
        'https://api.whatsapp.com/send?phone=5531992048792&text=Olá!%20Gostaria%20de%20conversar!',
        '_blank'
      );
    }
  };

  const MobileRecordCard = () => (
    <div className={styles.record_card_container}>
      <img
        src={smartphonesImg}
        className={styles.record_card_image}
        alt="3 smartphones com imagens de esportes abertas"
      />
      <h2>Grave cada lance com qualidade Full HD</h2>
      <p>Praparado para capturar cada jogada com precisão? Garanta já o seu kit!</p>
      <div className={styles.button_wrapper}>
        <Button
          text="Contratar câmera"
          backgroundColor="black"
          icon={cameraIcon}
          onClick={handleContractCameraWhatsapp}
        />
      </div>
    </div>
  );

  const DesktopRecordCard = () => (
    <div className={styles.record_card_container}>
      <div className={styles.record_card_wrapper}>
        <h2>Grave cada lance com qualidade Full HD</h2>
        <p>Praparado para capturar cada jogada com precisão? Garanta já o seu kit!</p>
        <div className={styles.button_wrapper}>
          <Button
            text="Contratar câmera"
            backgroundColor="black"
            icon={cameraIcon}
            onClick={handleContractCameraWhatsapp}
          />
        </div>
      </div>
      <img
        src={smartphonesImg}
        className={styles.record_card_image}
        alt="3 smartphones com imagens de esportes abertas"
      />
    </div>
  );

  return windowWidth >= 768 ? <DesktopRecordCard /> : <MobileRecordCard />;
};

export default RecordCard;
