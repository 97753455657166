import { useContext, useEffect, useState } from 'react';
import styles from './index.module.css';
import SearchButton from '../SearchButton';
import SearchDropdown from '../SearchDropdown';
import { convertToArenaOptions, convertToCourtOptions } from '../../../../utils/index';
import { SearchContext } from '../..';

interface VideoSearchProps {
  dataArena: any;
  onSearch: () => void;
}

function VideoSearch({ dataArena, onSearch }: VideoSearchProps) {
  const { court, arena, setArena, setCourt, date, setDate } = useContext(SearchContext);
  const [arenaOptions, setArenaOptions] = useState();
  const [courtOptions, setCourtOptions] = useState();

  useEffect(() => {
    if (dataArena) {
      setArenaOptions(convertToArenaOptions(dataArena));
    }
  }, [dataArena]);

  useEffect(() => {
    if (arena) {
      setCourtOptions(convertToCourtOptions(arena));
    }
  }, [arena]);

  const isDisabled = arena && court && date === '';

  return (
    <div className={styles.video_search_container}>
      <h2>Seus Vídeos</h2>
      <div className={styles.info}>
        <p>
          Arena: <span>{arena ? arena.value : 'N/A'}</span>
        </p>
        <p>
          Quadra: <span>{court ? court.value : 'N/A'}</span>
        </p>
      </div>
      <div className={styles.form}>
        <div className={styles.form_group}>
          <label htmlFor="arena">Arena</label>
          <SearchDropdown
            selectedOption={arena}
            options={arenaOptions}
            placeholder="Arena"
            onSelect={setArena}
          />
        </div>
        <div className={styles.form_group}>
          <label htmlFor="court">Quadra</label>
          <SearchDropdown
            selectedOption={court}
            options={courtOptions}
            placeholder="Quadra"
            onSelect={setCourt}
          />
        </div>
        <div className={styles.form_group}>
          <label htmlFor="date">Data</label>
          <input
            id="date"
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            placeholder="DD/MM/AAAA"
          />
        </div>
        <SearchButton onClick={onSearch} isDisabled={isDisabled} />
      </div>
    </div>
  );
}

export default VideoSearch;
