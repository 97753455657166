const baseURL = 'https://apertai.uc.r.appspot.com';
// /videos?state=mg&city=belohorizonte&name=Sagrada Beach&date=2024-10-21
export const getVideos = async ({ queryKey }: any) => {
  const [, state, city, arena, date, subcourt] = queryKey;
  const response = await fetch(
    `${baseURL}/videos/subcourt?state=${state}&city=${city}&name=${arena}&date=${date}&subcourt=${subcourt.replace(/\s/g, '')}`
  );

  return await response.json();
};

export const getArena = async ({ queryKey }: any) => {
  const [, city] = queryKey;
  const response = await fetch(`${baseURL}/arena?city=${city}`);

  return await response.json();
};
