import styles from './index.module.css';
import { ReactComponent as PlayButton } from '../../../../assets/images/play-button.svg';

interface SearchButtonProps {
  onClick: () => void;
  isBlack?: boolean;
  isDisabled?: boolean;
}

function SearchButton({ onClick, isBlack = false, isDisabled = true }: SearchButtonProps) {
  return (
    <button
      className={`${styles.button} ${isBlack && styles.button_black}`}
      onClick={onClick}
      disabled={isDisabled}>
      <div className={styles.button_text}>Buscar vídeos</div>
      <PlayButton style={{ width: 24, height: 24, filter: !isBlack ? 'invert(1)' : 'none' }} />
    </button>
  );
}

export default SearchButton;
