import { useState } from 'react';
import styles from './index.module.css';
import { DropdownOption } from '../../../../types';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';

interface SearchDropdownProps {
  selectedOption: DropdownOption | undefined;
  options: DropdownOption[] | undefined;
  placeholder: string;
  onSelect: (option: DropdownOption) => void;
}

function SearchDropdown({ selectedOption, options, placeholder, onSelect }: SearchDropdownProps) {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (option: DropdownOption) => {
    onSelect(option);
    setIsOpen(false);
  };

  return (
    <div className={styles.dropdownContainer}>
      <div className={styles.dropdownHeader} onClick={() => setIsOpen(!isOpen)}>
        <span>{selectedOption ? selectedOption.value : placeholder}</span>
        <span className={styles.arrow}>{isOpen ? <ExpandLess /> : <ExpandMore />}</span>
      </div>

      {isOpen && (
        <div className={styles.dropdownListContainer}>
          <ul className={styles.dropdownList}>
            {options && options.length > 0
              ? options.map((option, index) => (
                  <li
                    key={index}
                    className={styles.dropdownItem}
                    onClick={() => handleSelect(option)}>
                    {option.value}
                  </li>
                ))
              : undefined}
          </ul>
        </div>
      )}
    </div>
  );
}

export default SearchDropdown;
