export const convertToArenaOptions = (dataArena: any) => {
  return dataArena.map((item: any) => {
    return {
      id: item.id,
      value: item.name,
      subCourts: item.subCourts
    };
  });
};

export const convertToCourtOptions = (arena: any) => {
  if (!arena) {
    return;
  }
  return arena.subCourts.map((court: any) => ({
    id: court,
    value: court
  }));
};

export const isOlderThan16 = (birthDateString: string | Date): boolean => {
  const birthDate = new Date(birthDateString);
  const today = new Date();

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age >= 16;
};

export const handleContractCameraWhatsapp = () => {
  const confirmAction = window.confirm(
    'Você será redirecionado para uma conversa de WhatsApp. Deseja continuar?'
  );
  if (confirmAction) {
    window.open(
      'https://api.whatsapp.com/send?phone=5531992048792&text=Olá!%20Gostaria%20de%20conversar!',
      '_blank'
    );
  }
};
