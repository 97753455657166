import VideoItem from '../VideoItem';
import styles from './index.module.css';

interface VideosListProps {
  dataVideos: any;
  isPending?: boolean;
}

function VideosList({ dataVideos }: VideosListProps) {
  return (
    <div className={styles.video_container}>
      {dataVideos &&
        dataVideos.length > 0 &&
        dataVideos.map((video: any, index: number) => {
          return video.videoUrl && <VideoItem key={video.videoUrl + index} video={video} />;
        })}
    </div>
  );
}

export default VideosList;
