import styles from './index.module.css';

interface DownloadButtonProps {
  url: string;
  time: string;
}

function DownloadButton({ url, time }: DownloadButtonProps) {
  const handleDownload = async (event: any) => {
    event.preventDefault();

    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error('Network response was not ok');

      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = `${time}.mp4`;

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Failed to download file', error);
    }
  };

  return (
    <button onClick={handleDownload} className={styles.button}>
      Baixar
    </button>
  );
}

export default DownloadButton;
