import ReactPlayer from 'react-player';
import styles from './index.module.css';
import DownloadButton from '../DownloadButton';
import logo from '../../../../assets/images/logo.svg';

function VideoItem({ video }: any) {
  return (
    <div key={video.time} className={styles.video_item_container}>
      <div className={styles.video_wrapper}>
        <ReactPlayer
          url={video.videoUrl}
          controls
          width="100%"
          height="100%"
          light={video.thumbnailUrl ?? logo}
        />
      </div>
      <div className={styles.video_actions}>
        <p className={styles.replay_time}>
          Replay de: <span>{video.time}</span>
        </p>
        <DownloadButton url={video.videoUrl} time={video.time} />
      </div>
    </div>
  );
}

export default VideoItem;
