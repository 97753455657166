import { Button, InputField, Navbar } from '../../components';
import styles from './index.module.css';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { auth } from '../../api/firebase';
import { useNavigate } from 'react-router-dom';
import { useWindowWidth } from '../../hooks/WindowSizeContext';
import { useState } from 'react';
import { AcceptTerms } from './components';
import racketImg from '../../assets/images/racket.webp';
import { isOlderThan16 } from '../../utils/index';

const schema = z.object({
  name: z.string().min(3, { message: 'Adicione um nome válido.' }),
  phoneNumber: z.string().min(11, { message: 'Confira o número de celular.' }),
  email: z.string().email({ message: 'Email inválido, por favor conferir!' }),
  password: z.string().min(6, { message: 'A senha precisa ter pelo menos 6 caracteres.' }),
  birthday: z.string().refine((val) => isOlderThan16(val), {
    message: 'Você deve ter 16 anos ou mais para cadastrar.'
  })
});

function SignUp() {
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  const handleCheck = (checked: boolean) => {
    setIsTermsAccepted(checked);
  };
  const { windowWidth } = useWindowWidth();
  const navigate = useNavigate();

  const signupWithEmail = async (data: any) => {
    try {
      const { email, password, name } = data;
      const { user } = await createUserWithEmailAndPassword(auth, email, password);

      await updateProfile(user, {
        displayName: name
      });

      navigate('/login');
    } catch (e) {
      console.error(e);
    }
  };

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({ resolver: zodResolver(schema) });

  return (
    <>
      <Navbar menuTextColor="white" />
      <div className={styles.signup_container}>
        {windowWidth > 768 && (
          <img
            src={racketImg}
            className={styles.signup_image}
            alt="Raquetes de beach tennis coloridas, com bolas de tênis espalhadas pela areia."
          />
        )}
        <div className={styles.signup_form}>
          <h2>Cadastrar Conta</h2>
          <form onSubmit={handleSubmit(signupWithEmail)}>
            <InputField
              label="Nome Completo"
              placeholder="Fulano de Tal"
              register={register('name')}
              error={errors.name?.message}
            />
            <InputField
              label="Email"
              placeholder="fulano@gmail.com"
              register={register('email')}
              error={errors.email?.message}
            />
            <InputField
              label="Celular"
              placeholder="(31) 99999-9999"
              register={register('phoneNumber')}
              error={errors.phoneNumber?.message}
              mask="(99) 99999-9999"
            />
            <InputField
              label="Data de nascimento"
              type="date"
              placeholder="DD/MM/AAAA"
              register={register('birthday')}
              error={errors.birthday?.message}
            />
            <InputField
              label="Crie uma senha"
              placeholder="*******"
              type="password"
              register={register('password')}
              error={errors.password?.message}
            />
            <AcceptTerms checked={isTermsAccepted} onCheck={handleCheck} />
            <Button
              backgroundColor="white"
              text="Cadastrar"
              type="submit"
              className={styles.button_signup}
              disabled={!isTermsAccepted}
            />
          </form>
        </div>
      </div>
    </>
  );
}

export default SignUp;
