import { useQuery } from '@tanstack/react-query';
import { Navbar } from '../../components';
import { Search, SearchForm, VideosList } from './components';
import { getArena, getVideos } from '../../api';
import { createContext, useEffect, useState } from 'react';

export const SearchContext = createContext<any>(undefined);

function Videos() {
  const [isFirstStep, setIsFirstStep] = useState(true);
  const [state, setState] = useState();
  const [city, setCity] = useState();
  const [court, setCourt] = useState<any>();
  const [arena, setArena] = useState<any>();
  const [date, setDate] = useState('');
  const [dataVideos, setDataVideos] = useState();
  const [shouldFetchVideos, setShouldFetchVideos] = useState(false);

  const useHandleSearch = () => {
    if (isFirstStep) {
      setIsFirstStep(false);
    }
    setShouldFetchVideos(true);
    refetch();
  };

  const { data, refetch } = useQuery({
    queryKey: ['getVideos', 'mg', 'belohorizonte', arena?.value ?? '', date, court?.value ?? ''],
    queryFn: getVideos,
    enabled: shouldFetchVideos && !!date && !!arena
  });

  useEffect(() => {
    setDataVideos(data);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    shouldFetchVideos && setShouldFetchVideos(false);
  }, [data, shouldFetchVideos]);

  //TODO: Change "Belo Horizonte" to a dynamic city
  const { data: dataArena } = useQuery({
    queryKey: ['getArena', 'Belo Horizonte'],
    queryFn: getArena
  });

  return (
    <SearchContext.Provider
      value={{
        city,
        setCity,
        state,
        setState,
        court,
        setCourt,
        arena,
        setArena,
        date,
        setDate,
        dataArena
      }}>
      <Navbar menuTextColor="white" />
      {isFirstStep ? (
        <SearchForm onSearch={useHandleSearch} dataArena={dataArena} />
      ) : (
        <>
          <Search dataArena={dataArena} onSearch={useHandleSearch} />
          <VideosList dataVideos={dataVideos} />
        </>
      )}
    </SearchContext.Provider>
  );
}

export default Videos;
