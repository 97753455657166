import { Link } from 'react-router-dom';
import styles from './index.module.css';

interface GoogleButtonProps {
  imgPath: string;
  title: string;
  description: string;
  url: string;
}

const ArenaCard = ({ imgPath, title, description, url }: GoogleButtonProps) => {
  return (
    <div className={styles.arena_card_container}>
      <img src={imgPath} className={styles.arena_card_image} alt="Imagem da Arena" />
      <div className={styles.arena_card_wrapper}>
        <h3 className={styles.arena_card_title}>{title}</h3>
        <p className={styles.arena_card_description}>{description}</p>
        <Link to={`/videos${url}`} className={styles.arena_card_button}>
          Ver vídeos
        </Link>
      </div>
    </div>
  );
};

export default ArenaCard;
