import { createContext, useState, useEffect, useContext, ReactNode } from 'react';

interface WindowSizeContextType {
  windowWidth: number;
}

const WindowSizeContext = createContext<WindowSizeContextType | undefined>(undefined);

interface WindowSizeProviderProps {
  children: ReactNode;
}

export const WindowSizeProvider = ({ children }: WindowSizeProviderProps) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <WindowSizeContext.Provider value={{ windowWidth }}>{children}</WindowSizeContext.Provider>
  );
};

export const useWindowWidth = (): WindowSizeContextType => {
  const context = useContext(WindowSizeContext);

  if (!context) {
    throw new Error('useWindowWidth must be used within a WindowSizeProvider');
  }

  return context;
};
