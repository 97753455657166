import { Navbar } from '../../components';

function ContractCamera() {
  return (
    <>
      <Navbar menuTextColor="white" />
    </>
  );
}

export default ContractCamera;
