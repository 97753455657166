import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer/Footer';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { WindowSizeProvider } from './hooks/WindowSizeContext';
import { Home, Login, SignUp, Videos, ContractCamera } from './pages';
import { Navigate } from 'react-router-dom';
import { createContext, useContext } from 'react';
import { useState } from 'react';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import { DrawerBody } from './components/Navbar/Navbar';
import ScrollToTopOnMount from './hooks/ScrollToTopOnMount';

interface ProtectedRouteProps {
  element: any;
}

function ProtectedRoute({ element }: ProtectedRouteProps) {
  const accessToken = localStorage.getItem('accessToken');

  if (!accessToken) {
    return <Navigate to="/login" replace />;
  }

  return element;
}

const DrawerContext = createContext<any>(null);

const App = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen((prev) => !prev);
  };
  const queryClient = new QueryClient();

  return (
    <DrawerContext.Provider value={{ isDrawerOpen, toggleDrawer }}>
      <WindowSizeProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <ScrollToTopOnMount />
            <Drawer
              style={{ background: '#F2F2F2' }}
              open={isDrawerOpen}
              onClose={toggleDrawer}
              direction="right"
              size={275}>
              <DrawerBody />
            </Drawer>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/videos" element={<ProtectedRoute element={<Videos />} />} />
              <Route path="/contract-camera" element={<ContractCamera />} />
            </Routes>
            <Footer />
          </BrowserRouter>
        </QueryClientProvider>
      </WindowSizeProvider>
    </DrawerContext.Provider>
  );
};

export const useDrawer = () => {
  return useContext(DrawerContext);
};

export default App;
